import * as React from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

const Box = React.forwardRef(({ children, className }, ref) => {
	return (
		<div
			ref={ref}
			className={classNames(
				'overflow-hidden flex flex-col w-full rounded-20 md:rounded-30 lg:rounded-40',
				className
			)}
		>
			{children}
		</div>
	)
})

Box.propTypes = {
	children: node.isRequired,
	className: string
}

export default Box
