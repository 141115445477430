import * as React from 'react'
import {
	bool,
	func,
	arrayOf,
	shape,
	string,
	number,
	oneOfType
} from 'prop-types'
import Downshift from 'downshift'
import classNames from 'classnames'
import Input from '@/utils/Input'

const itemToString = i => (i ? i.value : '')
const sanitizeInputs = item => {
	return typeof item === 'string' ? item.toLowerCase().trim() : `${item}`
}

function Options({
	items,
	selectedItem,
	inputValue,
	value,
	highlightedIndex,
	getItemProps
}) {
	return (
		<ul
			className="absolute z-10 w-full overflow-auto bg-white border border-grey-20 rounded-b-10 -mt-md pt-xl pb-sm"
			style={{ maxHeight: '60vh', minHeight: 100 }}
		>
			{items
				.filter(item => {
					// show the full list if we have selected an item
					// show the full list if the first character is a space
					if (
						(selectedItem && selectedItem.value === inputValue) ||
						inputValue === ' '
					)
						return true
					// only show items that match
					return (
						!inputValue ||
						sanitizeInputs(item.value).includes(sanitizeInputs(inputValue))
					)
				})

				.map((item, index) => (
					<li
						data-testid="select-option"
						className={classNames('h-48 px-md flex items-center', {
							'bg-primary-5 text-accent': value === item.value,
							'bg-primary-5': highlightedIndex === index,
							'opacity-50': item.isDisabled,
							'cursor-pointer hover:bg-primary-5': !item.isDisabled
						})}
						key={item.value}
						{...(!item.isDisabled &&
							getItemProps({
								item,
								index
							}))}
					>
						{item.value}
					</li>
				))}
		</ul>
	)
}

Options.propTypes = {
	isOpen: bool,
	items: arrayOf(shape({ value: string.isRequire })).isRequired,
	selectedItem: shape({
		value: oneOfType([string, number])
	}),
	inputValue: oneOfType([string, number]),
	value: oneOfType([string, number]),
	highlightedIndex: number,
	getItemProps: func
}

function Select({
	error = false,
	items,
	onChange,
	placeholder = 'Please Select',
	value,
	forceValue = false,
	setForceValue,
	testid = 'select'
}) {
	return (
		<Downshift onChange={onChange} itemToString={itemToString}>
			{({
				getInputProps,
				getMenuProps,
				getItemProps,
				isOpen,
				openMenu,
				selectedItem,
				highlightedIndex,
				inputValue
			}) => (
				<div className="relative">
					<div className="relative z-20">
						<Input
							isSelectInput
							error={error}
							data-testid={testid}
							onClick={() => {
								if (!isOpen) {
									openMenu()
								}
							}}
							type="text"
							className={classNames(
								'h-48 pl-md cursor-pointer text-black placeholder-grey-40',
								{
									'border-s-error-50 border-b-0': error,
									'border-primary-60': !error
								}
							)}
							{...getInputProps({
								placeholder,
								value: value || value == '' ? value : inputValue,
								onChange: () => {
									if (forceValue) setForceValue(!forceValue)
								}
							})}
						/>
					</div>
					<div {...getMenuProps()}>
						{isOpen && (
							<Options
								isOpen={isOpen}
								items={items}
								selectedItem={selectedItem}
								inputValue={forceValue ? value : inputValue}
								value={value}
								highlightedIndex={highlightedIndex}
								getInputProps={getInputProps}
								getItemProps={getItemProps}
							/>
						)}
					</div>
				</div>
			)}
		</Downshift>
	)
}

Select.propTypes = {
	error: oneOfType([bool, string]),
	onChange: func,
	setForceValue: func,
	placeholder: oneOfType([string, number]),
	items: arrayOf(shape({ value: string.isRequire })).isRequired,
	value: oneOfType([string, number]),
	forceValue: bool,
	testid: string
}

export default Select
