import * as React from 'react'
import { string, node } from 'prop-types'
import classNames from 'classnames'
import Text from '@/utils/Text'

function LabelText({ children, className, as = 'h3' }) {
	return (
		<Text
			as={as}
			inline
			className={classNames(
				'text-mob-lg md:text-lg font-h-light mb-lg',
				className
			)}
		>
			{children}
		</Text>
	)
}

LabelText.propTypes = {
	children: node.isRequired,
	className: string,
	as: string
}

export default LabelText
