import * as React from 'react'
import { string, node, bool, oneOfType } from 'prop-types'
import classNames from 'classnames'
import DownArrow from '@/icons/icon-arrow-down.svg'
import Node from './Node'
import Text from './Text'
import IconWrapper from './IconWrapper'

const Input = React.forwardRef(
	(
		{
			as = 'input',
			children,
			className,
			wrapperClassName,
			error,
			isSelectInput = false,
			showErrors = true,
			...rest
		},
		ref
	) => {
		return (
			<div
				className={classNames(
					{ 'bg-s-error-bg-50': error && showErrors },
					'rounded-10',
					wrapperClassName
				)}
			>
				<div className="relative">
					<Node
						as={as}
						className={classNames(
							'block w-full text-base text-grey apperance-none p-sm rounded-10 border-2 outline-none leading-none',
							className,
							{
								'border-s-error-50': error,
								'border-b-0': error && showErrors,
								'border-primary-60 focus:border-primary-80': !error
							},
							{ 'h-40 md:h-48': as !== 'textarea' }
						)}
						{...rest}
						ref={ref}
					>
						{children}
					</Node>
					{isSelectInput && (
						<span className="absolute inset-y-0 z-20 flex items-center justify-center my-auto pointer-events-none w-xl h-xl right-sm">
							<IconWrapper
								icon={DownArrow}
								wrapperClassName="text-primary w-md"
							/>
						</span>
					)}
				</div>
				{error && showErrors && (
					<Text
						inline
						data-testid="input-error"
						className="text-sm text-white p-sm"
					>
						{error}
					</Text>
				)}
			</div>
		)
	}
)

Input.propTypes = {
	children: node,
	className: string,
	as: string,
	error: oneOfType([bool, string, node]),
	showErrors: bool,
	wrapperClassName: string,
	isSelectInput: bool
}

export default Input
