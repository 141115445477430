import * as React from 'react'
import { string, number } from 'prop-types'
import classNames from 'classnames'
import Input from '@/utils/Input'

const TextArea = React.forwardRef(({ className, rows = 10, ...rest }, ref) => {
	return (
		<Input
			rows={rows}
			as="textarea"
			className={classNames(className)}
			ref={ref}
			{...rest}
		/>
	)
})

TextArea.propTypes = {
	className: string,
	rows: number
}

export default TextArea
